import { Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Footer from 'components/Footer';
import LoadingIcon from 'components/LoadingIcon';
import SideNav from 'components/SideNav';
import useLoadUser from 'hooks/loadUser';
import useUser from 'hooks/useUser';
import AllEvents from 'pages/AllEvents';
import AllGroups from 'pages/AllGroups';
import BigScreen from 'pages/BigScreen';
import CreateExternalUserPage from 'pages/CreateExternalUser';
import CreateOrEditEventPage from 'pages/CreateOrEditEventPage';
import CreateOrEditGroupPage from 'pages/CreateOrEditGroupPage';
import DeleteUserPage from 'pages/DeleteUserPage';
import EventPage from 'pages/EventPage';
import GroupPage from 'pages/GroupPage';
import LocationPage from 'pages/LocationPage';
import ManageEventsPage from 'pages/ManageEventsPage';
import ManageGroupsPage from 'pages/ManageGroupsPage';
import ManagePastEventsPage from 'pages/ManagePastEventsPage';
import ManageUsersPage from 'pages/ManageUsersPage';
import ManagementPage from 'pages/ManagementPage';
import SchedulePage from 'pages/SchedulePage';
import MyAttendances from 'pages/MyAttendances';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { themeState } from 'atoms/themeState';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import UserProfile from './pages/UserProfile';
import { useAuth0 } from '@auth0/auth0-react';
import { createInitPush } from 'api/firebase';
import { addAccessTokenInterceptor } from 'api/axiosInstance';
import Snowfall from 'react-snowfall';

function App() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const theme = useRecoilValue(themeState);
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const { isAuthenticated, isLoadingAuth0 } = useLoadUser();
  const { location, isCoreMemberOrAdmin, isAdmin, isLoading } = useUser();
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  useEffect(() => {
    if (isAuthenticated) {
      addAccessTokenInterceptor(
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout
      );
    }
  }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect, logout]);

  useEffect(() => {
    if (!isAuthenticated) return;

    navigator.serviceWorker.ready.then(async (registration) => {
      const initPush = createInitPush(registration);
      await initPush(
        await getAccessTokenSilently(),
        window.Notification.permission
      );
    });
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoadingAuth0 || isLoading) {
    return <LoadingIcon />;
  }
  return (
    <>
      <BrowserRouter>
        {(currentMonth === 11 || currentMonth === 0 || currentMonth === 1) && (
          <Snowfall />
        )}
        <ThemeProvider theme={createTheme(theme)}>
          <Navbar openMenu={() => setIsOpen(true)} />
          {(isAuthenticated || isMobile) && (
            <SideNav isOpen={isOpen} setIsOpen={setIsOpen} />
          )}
          <Box
            display="flex"
            justifyContent="center"
            minHeight="calc(100% - 64px - 100px)"
          >
            <Box
              sx={{
                paddingX: 2,
                marginLeft: isMobile || !isAuthenticated ? '0' : '19.75em',
                maxWidth: '1024px',
                width: '100%',
              }}
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    isAuthenticated ? (
                      <Navigate to={`/locations/${location}`} />
                    ) : (
                      <Home />
                    )
                  }
                />
                <Route path="locations" element={<Home />} />
                <Route path="locations/:city" element={<LocationPage />} />
                <Route
                  path="/management"
                  element={
                    !isCoreMemberOrAdmin ? (
                      <Navigate to="/" />
                    ) : (
                      <ManagementPage />
                    )
                  }
                />
                <Route
                  path="/management/users"
                  element={!isAdmin ? <Navigate to="/" /> : <ManageUsersPage />}
                />
                <Route
                  path="/management/users/create"
                  element={
                    !isAdmin ? <Navigate to="/" /> : <CreateExternalUserPage />
                  }
                />
                <Route
                  path="/management/users/delete"
                  element={!isAdmin ? <Navigate to="/" /> : <DeleteUserPage />}
                />
                <Route
                  path="/management/groups"
                  element={
                    !isCoreMemberOrAdmin ? (
                      <Navigate to="/" />
                    ) : (
                      <ManageGroupsPage />
                    )
                  }
                />
                <Route
                  path="/management/groups/create"
                  element={
                    !isCoreMemberOrAdmin ? (
                      <Navigate to="/" />
                    ) : (
                      <CreateOrEditGroupPage />
                    )
                  }
                />
                <Route
                  path="/management/groups/edit/:groupId"
                  element={
                    !isCoreMemberOrAdmin ? (
                      <Navigate to="/" />
                    ) : (
                      <CreateOrEditGroupPage />
                    )
                  }
                />
                <Route
                  path="/management/events"
                  element={
                    !isCoreMemberOrAdmin ? (
                      <Navigate to="/" />
                    ) : (
                      <ManageEventsPage />
                    )
                  }
                />
                <Route
                  path="/management/events/past"
                  element={
                    !isCoreMemberOrAdmin ? (
                      <Navigate to="/" />
                    ) : (
                      <ManagePastEventsPage />
                    )
                  }
                />
                <Route
                  path="/management/events/create"
                  element={
                    !isAuthenticated ? (
                      <Navigate to="/" />
                    ) : (
                      <CreateOrEditEventPage />
                    )
                  }
                />
                <Route
                  path="/management/events/edit/:eventId"
                  element={<CreateOrEditEventPage />}
                />
                <Route path="/bigscreen" element={<BigScreen />} />
                <Route
                  path="/user"
                  element={
                    !isAuthenticated ? <Navigate to="/" /> : <UserProfile />
                  }
                />
                <Route
                  path="/myattendances"
                  element={
                    !isAuthenticated ? <Navigate to="/" /> : <MyAttendances />
                  }
                />
                <Route
                  path="/schedule"
                  element={
                    !isAuthenticated ? <Navigate to="/" /> : <SchedulePage />
                  }
                />
                {/* <Route path="/notifications" element={<Notifications />} /> */}
                <Route path="/groups" element={<AllGroups />} />
                <Route path="/groups/:id" element={<GroupPage />} />
                <Route path="/events" element={<AllEvents />} />
                <Route path="/events/:id" element={<EventPage />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Box>
          </Box>
          <Footer />
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
