import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SocialEventForm } from 'types';
import { CompressImage, GiveFileImageType } from '../../helpers/CompressImage';
import GetImageUrl from '../../helpers/GetImage';
import ChooseFile from './ChooseFile';
import ChooseStock from './ChooseStock';
import './index.scss';
import { isMobile } from 'react-device-detect';

function ImageDrop(props: {
  errorState?: any;
  setErrorState?: any;
  setFormState: any;
  prevImage?: string;
}) {
  const { errorState, setErrorState, setFormState, prevImage } = props;
  const [file, setFile] = useState(null);

  const [preview, setPreview] = useState('');

  const fileSelectedHandler = async (event: any) => {
    const file = event.target.files[0];
    if (file.size > 5e6 * 8) {
      alert(
        'The file size is too big. Choose a smaller file size and try again'
      );
    } else {
      setFile(await CompressImage(file));
    }
  };

  const stockSelectedHandler = async (file: File) => {
    const newFile = GiveFileImageType(file);
    setFile(await CompressImage(newFile));
  };

  useEffect(() => {
    if (!file) {
      setPreview('');
      setFormState((prev: SocialEventForm) => ({
        ...prev,
        file: new Blob(),
      }));
      return;
    }
    setFormState((prev: SocialEventForm) => ({
      ...prev,
      file: file,
    }));

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [file, setFormState]);

  useEffect(() => {
    const isPng = new RegExp('[.*.png.*]');
    const url = isPng.test(prevImage ?? '')
      ? GetImageUrl(prevImage ?? '')
      : prevImage;
    setPreview(url ?? '');
  }, [prevImage]);

  useEffect(() => {
    setErrorState((prev: any) => ({ ...prev, file: !preview }));
  }, [preview, setErrorState]);

  function removeImage() {
    setFile(null);
    setPreview('');
  }

  // preview equals the name of a PNG file and not an actual image in edit

  const ChosenFile = () => {
    return (
      <>
        {preview ? (
          <Grid container item className="container-selected-image">
            <img
              alt={'header'}
              src={preview}
              className="previewSelectedImage"
            />
            <Box className="cancel-icon-background"></Box>
            <CancelIcon
              className="cancel-icon"
              onClick={() => {
                removeImage();
              }}
            />
          </Grid>
        ) : (
          <ChooseFile fileSelectedHandler={fileSelectedHandler} />
        )}
      </>
    );
  };

  return (
    <Grid
      container
      item
      direction="column"
      className="uploadFile"
      sx={{ border: errorState.file ? '1px solid red' : '' }}
    >
      <Grid container={!isMobile} item columnSpacing={5}>
        <Grid item lg={6} md={12} sm={12}>
          <Typography variant="h3" pt={2} pb={2}>
            Upload Image
            <Box component="span" color="red" ml={0.5}>
              *
            </Box>
          </Typography>

          <Grid
            item
            container
            height={isMobile ? 'auto' : '362px'}
            className="uploadImageContainer"
          >
            <ChosenFile />
          </Grid>
        </Grid>
        <Grid item lg={6} md={12} sm={12}>
          <Typography pt={4} height={'28px'} color={'#666'}>
            ...or search for an image
          </Typography>
          <Grid item className="stockImageContainer">
            <ChooseStock stockSelectedHandler={stockSelectedHandler} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ImageDrop;
