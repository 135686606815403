import React, { useState, useEffect } from 'react';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import { Box, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import './index.scss';

interface Picture {
  name: string;
  contentType: string;
  content: string;
  imageId: string;
  imageUrl: string;
}

function DeleteImageList(props: { groupId: any; carouselData?: Picture[] }) {
  const { carouselData, groupId } = props;
  const { REACT_APP_API_URI } = process.env;
  const [pictures, setPictures] = useState<Picture[]>([]);
  const [confirmationBoxStates, setConfirmationBoxStates] = useState<boolean[]>(
    []
  );

  useEffect(() => {
    if (carouselData) {
      setPictures(carouselData);
      setConfirmationBoxStates(new Array(carouselData.length).fill(false));
    }
  }, [carouselData]);

  const handleDelete = (imageId: string, index: number) => {
    fetch(
      `${REACT_APP_API_URI}/api/blobstorage/Delete-Carousel-pic/${groupId}/${imageId}`,
      {
        method: 'DELETE',
      }
    )
      .then(() => {
        setPictures((prevPictures) =>
          prevPictures.filter((picture) => picture.imageId !== imageId)
        );

        setConfirmationBoxStates((prevStates) => {
          const newStates = [...prevStates];
          newStates[index] = false;
          return newStates;
        });
      })
      .catch((error) => {
        console.error('Error deleting image:', error);
      });
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box>
            <h3>Delete Carousel Images</h3>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ul className="delete-list">
            {pictures.map((picture, index) => {
              return (
                <li
                  key={picture.imageId}
                  style={{ width: '15rem', textAlign: 'center' }}
                >
                  <div className="deleteCarousel-content">
                    <div className="delete-cards">
                      <img
                        className="card-images"
                        src={`data:${picture.contentType};base64,${picture.content}`}
                        alt={picture.name}
                      />
                      <Button
                        style={{ margin: '1rem', width: '80%' }}
                        onClick={() =>
                          setConfirmationBoxStates((prevStates) => {
                            const newStates = [...prevStates];
                            newStates[index] = true;
                            return newStates;
                          })
                        }
                        disableElevation
                        variant="contained"
                        sx={{
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#f44336',
                          },
                        }}
                        color="primary"
                      >
                        Remove
                      </Button>
                    </div>
                    <ConfirmationBox
                      open={confirmationBoxStates[index]}
                      title={`Are you sure that you want to delete this image?`}
                      content={''}
                      handleClose={() =>
                        setConfirmationBoxStates((prevStates) => {
                          const newStates = [...prevStates];
                          newStates[index] = false; // Close the confirmation box
                          return newStates;
                        })
                      }
                      handleResponse={(response) => {
                        if (response) handleDelete(picture.imageId, index);
                      }}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default DeleteImageList;
