import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import RemoveAttendeesDropdown from './RemoveAttendeesDropdown';
import { UserWithImage } from 'types';
import { isMobile } from 'react-device-detect';
import AddAttendeesDropdown from './AddAttendeesDropdown';
import useUser from 'hooks/useUser';

interface RemoveAttendeesDialogProps {
  open: boolean;
  onClose: () => void;
  onRemoveAttendees: () => void;
  allAttendees: UserWithImage[];
  eventId: number;
}

const RemoveAttendeesDialog = ({
  open,
  onClose,
  onRemoveAttendees,
  allAttendees,
  eventId,
}: RemoveAttendeesDialogProps) => {
  const { isCoreMemberOrAdmin } = useUser();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          overflow: isMobile ? 'hidden' : 'auto',
          flexWrap: isMobile ? 'wrap' : 'nowrap',
          gap: '2rem',
        }}
      >
        <RemoveAttendeesDropdown
          allAttendees={allAttendees}
          eventId={eventId}
          onRemoveAttendees={onRemoveAttendees}
        />
        {isCoreMemberOrAdmin && (
          <AddAttendeesDropdown
            allAttendees={allAttendees}
            eventId={eventId.toString()}
            onAddAttendees={onRemoveAttendees}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RemoveAttendeesDialog;
