import { Box, ImageList, ImageListItem, TextField } from '@mui/material';
import axios from 'axios';
import LoadingIcon from 'components/LoadingIcon';
import { useEffect, useRef, useState } from 'react';
import './index.scss';
import { isMobile } from 'react-device-detect';
const { REACT_APP_API_URI } = process.env;
const API_URL = 'https://api.unsplash.com/search/photos';
const IMAGES_PER_PAGE = 20;

interface ImageData {
  img: string;
  title: string;
  author: string;
  featured?: boolean;
}

interface UnsplashImage {
  id: string;
  urls: {
    small: string;
    regular: string;
  };
  alt_description: string;
}

const itemData: ImageData[] = [
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock1.jpg`,
    title: 'Breakfast',
    author: '@bkristastucchio',
    featured: true,
  },
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock2.jpg`,
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock3.jpg`,
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock4.jpg`,
    title: 'Coffee',
    author: '@nolanissac',
  },
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock5.jpg`,
    title: 'Hats',
    author: '@hjrc33',
  },
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock6.jpg`,
    title: 'Honey',
    author: '@arwinneil',
    featured: true,
  },
];

function ChooseStock(props: { stockSelectedHandler: any }) {
  //Converts the url images to file
  const urlToObject = async (image: string, title: string) => {
    const response = await fetch(image);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], title);
    return file;
  };

  const [images, setImages] = useState<ImageData[]>(itemData);
  const [query, setQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const fetchImages = async (searchQuery: string) => {
    if (!searchQuery) {
      setImages(itemData);
    } else {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${API_URL}?query=${searchQuery}&page=1&per_page=${IMAGES_PER_PAGE}&client_id=${process.env.REACT_APP_UNSPLASH_CLIENT_ID}`
        );
        const unsplashImages = data.results.map((image: UnsplashImage) => ({
          img: image.urls.regular,
          title: image.alt_description,
          author: '',
        }));
        setImages(unsplashImages);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      fetchImages(query);
    }, 300);
  }, [query]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const { stockSelectedHandler } = props;

  return (
    <>
      <Box className="image-search-container">
        <TextField
          type="text"
          placeholder="Padel"
          onChange={handleInputChange}
          label="Search"
          fullWidth
          sx={{ backgroundColor: 'white' }}
        />

        <Box height={'10px'} width={'100%'}></Box>
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <Box width={'100%'} maxHeight={'286px'} overflow={'auto'}>
            <ImageList
              variant="masonry"
              rowHeight={isMobile ? 200 : 110}
              cols={isMobile ? 1 : 2}
              gap={5}
              sx={{ margin: 0 }}
            >
              {images.length === 0 ? <p>No search results.</p> : ''}
              {images.map((image, index) => (
                <ImageListItem
                  key={index}
                  onClick={async () =>
                    stockSelectedHandler(
                      await urlToObject(image.img, image.title)
                    )
                  }
                >
                  <img
                    src={image.img}
                    alt={image.title}
                    className="listImage"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        )}
      </Box>
    </>
  );
}

export default ChooseStock;
