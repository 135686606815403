import { useAuth0 } from '@auth0/auth0-react';
import CalendarIcon from '@mui/icons-material/CalendarMonthRounded';
import CelebrationIcon from '@mui/icons-material/Celebration';
import GroupIcon from '@mui/icons-material/GroupRounded';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, Grid, Typography } from '@mui/material';
import useUser from 'hooks/useUser';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router';
import ProfileBox from '../Navbar/ProfileBox';
import EventMenu from './EventMenu';
import GroupMenu from './GroupMenu';
import SideLocationMenu from './SideLocationMenu';
import Snowfall from 'react-snowfall';

const SideNavContent = (props: { onClose?: () => void }) => {
  const { onClose } = props;
  const {
    location: userLocation,
    isAuthenticated,
    isCoreMemberOrAdmin,
  } = useUser();
  const navigate = useNavigate();
  const { logout, loginWithRedirect } = useAuth0();
  const path = useLocation();
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  function isCurrentPath(currentPath: string) {
    return path.pathname.toLowerCase() === currentPath.toLowerCase();
  }

  function includesCurrentPath(currentPath: string) {
    return path.pathname.toLowerCase().includes(currentPath.toLowerCase());
  }

  return (
    <>
      {(currentMonth === 11 || currentMonth === 0 || currentMonth === 1) && (
        <Snowfall snowflakeCount={50} />
      )}
      <Grid container item justifyContent="center">
        <ProfileBox onClose={onClose} />
        <Grid container item mt={2}>
          <>
            {isAuthenticated && isCoreMemberOrAdmin && (
              <Button
                disableElevation
                style={{ justifyContent: 'flex-start' }}
                fullWidth
                onClick={() => {
                  onClose?.();
                  navigate('/management');
                }}
                startIcon={<SettingsIcon htmlColor="white" />}
              >
                <Typography variant="h5" color="white">
                  Manage
                </Typography>
              </Button>
            )}
          </>
          {isMobile && (
            <Button
              disableElevation
              style={{ justifyContent: 'flex-start' }}
              fullWidth
              startIcon={<HomeIcon htmlColor="white" />}
              onClick={() => {
                onClose?.();
                navigate(`/locations/${userLocation}`);
              }}
            >
              <Typography variant="h5" color="white">
                Home
              </Typography>
            </Button>
          )}
          {isMobile && (
            <SideLocationMenu
              isCurrentPath={
                includesCurrentPath(`locations`) &&
                !isCurrentPath(`/locations/${userLocation}`)
              }
              onClose={() => onClose?.()}
            />
          )}
          {isAuthenticated && (
            <Button
              disableElevation
              style={{ justifyContent: 'flex-start' }}
              fullWidth
              startIcon={<CalendarIcon htmlColor="white" />}
              onClick={() => {
                onClose?.();
                navigate(`/schedule`);
              }}
            >
              <Typography variant="h5" color="white">
                Schedule
              </Typography>
            </Button>
          )}
          <Button
            disableElevation
            style={{ justifyContent: 'flex-start' }}
            fullWidth
            startIcon={<CelebrationIcon htmlColor="white" />}
            onClick={() => {
              onClose?.();
              navigate(`/events?city=${userLocation ?? 'all'}`);
            }}
          >
            <Typography variant="h5" color="white">
              Activities
            </Typography>
          </Button>
          {isAuthenticated && <EventMenu onClose={onClose} />}
          {/*
          {!isAuthenticated && (
            <Button
              disableElevation
              style={{ justifyContent: 'flex-start' }}
              fullWidth
              startIcon={<GroupIcon htmlColor="white" />}
              onClick={() => {
                onClose?.();
                navigate(`/groups?city=${userLocation ?? 'all'}`);
              }}
            >
              <Typography variant="h5" color="white">
                Groups
              </Typography>
            </Button>
          )}
          {isAuthenticated && <DropDownMenu onClose={onClose} />}
           */}
          <Button
            disableElevation
            style={{ justifyContent: 'flex-start' }}
            fullWidth
            startIcon={<GroupIcon htmlColor="white" />}
            onClick={() => {
              onClose?.();
              navigate(`/groups?city=${userLocation ?? 'all'}`);
            }}
          >
            <Typography variant="h5" color="white">
              Groups
            </Typography>
          </Button>
          {isAuthenticated && <GroupMenu onClose={onClose} />}
          {isMobile && isAuthenticated && (
            <Button
              disableElevation
              sx={{
                justifyContent: 'center',
                bottom: 0,
              }}
              fullWidth
              onClick={() => {
                onClose?.();
                logout({ logoutParams: { returnTo: window.origin } });
              }}
              startIcon={<LogoutIcon htmlColor="white" />}
            >
              <Typography variant="h5" color="white">
                Logout
              </Typography>
            </Button>
          )}
          {isMobile && !isAuthenticated && (
            <Button
              disableElevation
              sx={{
                justifyContent: 'center',
                bottom: 0,
              }}
              fullWidth
              onClick={() => {
                onClose?.();
                loginWithRedirect();
              }}
              startIcon={<LoginIcon htmlColor="white" />}
            >
              <Typography variant="h5" color="white">
                Log in
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SideNavContent;
