import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { registerSW } from 'swSetup';

const {
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_AUTH_API_URI,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REDIRECT_URI,
} = process.env;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Auth0Provider
        domain={REACT_APP_AUTH_DOMAIN || ''}
        clientId={REACT_APP_AUTH_CLIENT_ID || ''}
        authorizationParams={{
          redirect_uri: REACT_APP_AUTH_REDIRECT_URI,
          audience: REACT_APP_AUTH_API_URI,
          scope: 'openid profile email offline_access',
        }}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        useRefreshTokensFallback={true}
      >
        <App />
      </Auth0Provider>
    </RecoilRoot>
  </React.StrictMode>
);

registerSW();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
