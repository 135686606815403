import { isDev } from 'api/common';
import { useEffect, useRef, useState, useCallback } from 'react';
import { pushRecievedPublisher } from 'helpers/pubsub';
import useHttpGet from 'hooks/useHttpGet';
import useHttpDelete from 'hooks/useHttpDelete';
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/NotificationsNoneRounded';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActiveOutlined';
import {
  Button,
  Typography,
  IconButton,
  MenuItem,
  Divider,
  Menu,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isDesktop } from 'react-device-detect';
import axiosInstance from 'api/axiosInstance';
import { Notification } from 'types';

const mapApiNotification = (n: Notification): Notification => ({
  ...n,
  url: n.url ?? '/',
  id: typeof n.id === 'number' ? n.id.toString() : n.id,
});

export default function PushNotifications() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [realtimeNotifications, setRealtimeNotifications] = useState<
    Notification[]
  >([]);

  const {
    data: apiNotifications = [],
    isLoading,
    reload: reloadNotifications,
  } = useHttpGet<Notification[]>('/notifications');

  const handleMarkAsRead = useCallback(
    async (id: string) => {
      await axiosInstance.post(`/notifications/${id}/read`);
      reloadNotifications();
    },
    [reloadNotifications]
  );

  const { httpDelete: deleteNotification } = useHttpDelete();
  const { httpDelete: clearAll } = useHttpDelete();

  const notifications = [
    ...realtimeNotifications,
    ...(apiNotifications ?? []).map(mapApiNotification),
  ];
  const unreadCount = notifications.filter((n) => !n.isRead).length;

  const handleNotification = useCallback(
    (e: {
      payload: {
        data?: {
          title?: string;
          body?: string;
          url?: string;
        };
      };
    }) => {
      if (!e.payload?.data) {
        if (isDev) {
          console.warn('No data sent from server in push notification', {
            payload: e.payload,
          });
        }
        return;
      }

      const title = e.payload.data?.title ?? 'Untitled';
      const body = e.payload.data?.body ?? 'No description';
      const url = e.payload.data?.url ?? '/';

      if (!isDesktop) {
        const n = new window.Notification(title, { body });
        n.onclick = () => {
          window.open(url, window.name);
        };
      }

      const newNotification: Notification = {
        id: crypto.randomUUID(),
        title,
        body,
        url,
        createdAt: new Date().toISOString(),
        isRead: false,
      };

      setRealtimeNotifications((prev) => [newNotification, ...prev]);
    },
    []
  );

  useEffect(() => {
    pushRecievedPublisher.subscribe('notificationAlert', handleNotification);
    return () => {
      pushRecievedPublisher.unsubscribe('notificationAlert');
      setRealtimeNotifications([]);
    };
  }, [handleNotification]);

  const handleBellClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      if (!anchorEl) {
        setRealtimeNotifications([]);
        reloadNotifications();
      }
    },
    [reloadNotifications, anchorEl]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = useCallback(
    async (id: string) => {
      await deleteNotification(`/notifications/${id}`);
      reloadNotifications();
    },
    [deleteNotification, reloadNotifications]
  );

  const handleClearAll = useCallback(async () => {
    await clearAll('/notifications');
    reloadNotifications();
    setRealtimeNotifications([]);
  }, [clearAll, reloadNotifications]);

  const handleNotificationClick = useCallback(
    async (notification: Notification) => {
      if (!notification.isRead) {
        await handleMarkAsRead(notification.id.toString());
      }
      handleClose();
      navigate(notification.url ?? '/');
    },
    [handleMarkAsRead, navigate]
  );

  if (isLoading) {
    return (
      <IconButton size="large" disabled>
        <NotificationsIcon />
      </IconButton>
    );
  }

  return (
    <>
      <IconButton
        aria-controls={Boolean(anchorEl) ? 'notifications-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
        onClick={handleBellClick}
        size="large"
      >
        {unreadCount === 0 && <NotificationsIcon />}
        {unreadCount > 0 && (
          <>
            <Typography
              component="span"
              color="error"
              position="absolute"
              top={0}
              right={5}
            >
              {unreadCount}
            </Typography>
            <NotificationsActiveIcon color="error" />
          </>
        )}
      </IconButton>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'notifications-button',
        }}
        PaperProps={{
          sx: {
            maxHeight: 400,
            width: '350px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {notifications.length > 0
          ? [
              <MenuItem key="clear-all" sx={{ justifyContent: 'flex-end' }}>
                <Button size="small" onClick={handleClearAll}>
                  Clear All
                </Button>
              </MenuItem>,
              <Divider key="divider" />,
              ...notifications.map((n) => (
                <MenuItem
                  key={n.id}
                  onClick={() => handleNotificationClick(n)}
                  sx={{
                    bgcolor: n.isRead ? 'transparent' : 'action.hover',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    py: 1,
                  }}
                >
                  <Typography variant="subtitle2">{n.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {n.body}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {new Date(n.createdAt).toLocaleString()}
                  </Typography>
                  <IconButton
                    size="small"
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDelete(n.id.toString());
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </MenuItem>
              )),
            ]
          : [
              <MenuItem key="no-notifications" disabled>
                <Typography color="text.secondary">No notifications</Typography>
              </MenuItem>,
            ]}
      </Menu>
    </>
  );
}
