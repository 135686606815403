import { Loader } from '@googlemaps/js-api-loader';
import { Box, Grid, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useRef, useState } from 'react';
import { SocialEventForm } from 'types';

const key = process.env.REACT_APP_MAPS_KEY;

export default function MapWithSearchAndAutocomplete({
  eventFormState,
  setEventFormState,
  hasErrorState,
  setHasErrorState,
}: {
  eventFormState: SocialEventForm;
  setEventFormState: any;
  hasErrorState: any;
  setHasErrorState: any;
}) {
  const [query, setQuery] = useState('');
  const [addressOptions, setAddressOptions] = useState<string[]>([]);
  const [sentRequests, setSentRequests] = useState<number>(0);
  const map = useRef<google.maps.Map | null>(null);
  const mapContainer = useRef<HTMLDivElement>(null);
  const markers = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
  const infowindow = useRef<google.maps.InfoWindow | null>(null);
  const service = useRef<google.maps.places.PlacesService | null>(null);
  const autocompleteService =
    useRef<google.maps.places.AutocompleteService | null>(null);

  let loaderInstance: Loader | null = null;

  async function initMap() {
    if (!loaderInstance) {
      loaderInstance = new Loader({
        apiKey: key!,
        libraries: ['places', 'marker'],
        version: 'weekly',
      });
    }

    loaderInstance
      .load()
      .then(() => {
        map.current = new google.maps.Map(mapContainer.current as HTMLElement, {
          center: { lat: 60.711, lng: 8.958 },
          zoom: 6,
          disableDefaultUI: true,
          zoomControl: true,
          mapId: 'uluru',
        });
        infowindow.current = new google.maps.InfoWindow();
        service.current = new google.maps.places.PlacesService(map.current);
        autocompleteService.current =
          new google.maps.places.AutocompleteService();
        if (eventFormState.address) {
          executeSearch(eventFormState.address);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function executeSearch(query: string | null): void {
    if (!query || !map.current || !service.current) return;

    setSentRequests((prev) => prev + 1);
    const request = {
      query,
      fields: ['name', 'geometry'],
    };
    service.current.findPlaceFromQuery(
      request,
      (
        results: google.maps.places.PlaceResult[] | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          clearMarkers();
          markers.current = results.map((result) => createMarker(result));
          map.current?.setCenter(results[0].geometry!.location!);
          map.current?.setZoom(15);
        }
      }
    );
  }

  function clearMarkers() {
    if (!markers) return;
    for (let marker of markers.current) {
      marker.map = null;
    }
  }

  function createMarker(
    place: google.maps.places.PlaceResult
  ): google.maps.marker.AdvancedMarkerElement {
    if (!place.geometry || !place.geometry.location)
      throw new Error('Invalid location');

    const marker = new google.maps.marker.AdvancedMarkerElement({
      map: map.current,
      position: place.geometry.location,
      title: 'uluru',
    });

    google.maps.event.addListener(marker, 'click', () => {
      infowindow.current?.setContent(place.name || '');
      infowindow.current?.open(map.current);
    });

    return marker;
  }

  function fetchPlacePredictions(input: string) {
    if (!autocompleteService.current || !input) return;

    autocompleteService.current.getPlacePredictions(
      { input },
      (predictions, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          predictions = predictions.filter(
            (prediction, index, self) =>
              index ===
              self.findIndex((p) => p.description === prediction.description)
          );
          setAddressOptions(
            predictions.map((prediction) => prediction.description)
          );
        }
      }
    );
  }

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    if (sentRequests >= 10 || query.length <= 5) return;
    const id = setTimeout(() => fetchPlacePredictions(query), 500);
    return () => clearTimeout(id);
  }, [query]);

  return (
    <Grid container item gap={2}>
      <Typography variant="h3">
        Address Information
        <Box component="span" color="red" ml={0.5}>
          *
        </Box>
      </Typography>
      <Grid container item gap={2}>
        <Grid container item direction="column" spacing={2}>
          <Grid item md={5}>
            <Autocomplete
              placeholder="Maskinveien 24"
              disablePortal
              fullWidth
              autoSelect
              inputValue={query}
              value={eventFormState.address}
              onChange={(_, value) => {
                executeSearch(value);
                setHasErrorState((prev: any) => ({
                  ...prev,
                  address: !value,
                }));
                setEventFormState((prev: any) => ({
                  ...prev,
                  address: value || '',
                }));
              }}
              isOptionEqualToValue={(option, value) => true}
              onInputChange={(_, value: string) => {
                setQuery(value);
              }}
              options={addressOptions}
              filterOptions={(options) => options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Address"
                  placeholder="Maskinveien 24"
                  error={hasErrorState.address}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} className="autocomplete-option">
                  {option}
                </li>
              )}
            />
          </Grid>
          <Grid item md>
            <Box
              ref={mapContainer}
              style={{ height: '300px', width: '100%' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
