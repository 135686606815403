import React, { useState } from 'react';
import { UserBase, UserWithImage } from 'types';
import { FormControl, Button, Typography } from '@mui/material';
import useHttpWithData from 'hooks/useHttpWithData';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import UserSearch from 'components/UserSearch/UserSearch';

interface RemoveAttendeesDropdownProps {
  allAttendees: UserWithImage[];
  eventId: number;
  onRemoveAttendees: () => void;
}

const RemoveAttendeesDropdown = ({
  allAttendees,
  eventId,
  onRemoveAttendees,
}: RemoveAttendeesDropdownProps) => {
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const { send: removeAttendees, isLoading: isLoadingRemove } =
    useHttpWithData<any>({
      url: '/event-attendances/remove',
      method: 'put',
    });

  const [user, setUser] = useState<UserBase | null>(null);

  const handleRemoveAttendees = () => {
    if (!user) {
      return;
    }

    const attendeeToRemove = [{ AppUserId: user?.appUserId, EventId: eventId }];

    removeAttendees({
      eventAttendances: attendeeToRemove,
    })
      .then(() => {
        console.log('Attendee removed successfully:', attendeeToRemove);
        onRemoveAttendees();
        setUser(null);
      })
      .catch((error) => {
        console.error('Error removing attendee:', error);
      });
  };

  return (
    <div
      style={{
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography>Remove Attendee:</Typography>
      <FormControl sx={{ width: 280 }} variant="standard">
        <UserSearch
          setUser={(user) => {
            setUser(user);
          }}
          users={allAttendees}
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenConfirmationBox(true)}
        disabled={isLoadingRemove}
        disableElevation
        sx={{ height: '3rem', marginTop: '0.5rem' }}
      >
        Remove Attendee <PersonRemoveIcon sx={{ marginLeft: '0.5rem' }} />
      </Button>
      <ConfirmationBox
        open={openConfirmationBox}
        title={`Remove Selected Attendee?`}
        content={''}
        handleClose={() => {
          setOpenConfirmationBox(false);
        }}
        handleResponse={(response) => {
          if (response) handleRemoveAttendees();
        }}
      />
    </div>
  );
};
export default RemoveAttendeesDropdown;
