import { PersonAdd } from '@mui/icons-material';
import { Button, FormControl, Grid, Typography } from '@mui/material';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import UserSearch from 'components/UserSearch/UserSearch';
import useHttpGet from 'hooks/useHttpGet';
import useHttpWithData from 'hooks/useHttpWithData';
import { useState } from 'react';
import { AttendingUser, UserBase, UserWithImage } from 'types';

interface AddAttendeesDropdownProps {
  allAttendees: AttendingUser[];
  eventId: string;
  onAddAttendees: () => void;
}
interface EventAttendance {
  appUserId: string;
  eventId: string;
}
const AddAttendeesDropdown = ({
  allAttendees,
  eventId,
  onAddAttendees,
}: AddAttendeesDropdownProps) => {
  const { send: registerJoinAttendance, isLoading: isLoadingJoin } =
    useHttpWithData<EventAttendance>({
      url: '/event-attendances/join',
      method: 'put',
    });

  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);

  const { data: users } = useHttpGet<UserWithImage[]>('/users');

  const [user, setUser] = useState<UserBase | null>(null);

  const filterUsers = (users: UserWithImage[] | undefined) => {
    const allAttendingIds = new Set(allAttendees.map((user) => user.appUserId));
    const filteredUsers = users?.filter(
      (user) => !allAttendingIds.has(user.appUserId)
    );
    return filteredUsers;
  };

  const handleAddAttendees = () => {
    if (!user) {
      return;
    }
    const attendeesToAdd = { appUserId: user?.appUserId, eventId: eventId };
    const register = registerJoinAttendance(attendeesToAdd)
      .then(() => {
        onAddAttendees();
        setUser(null);
      })
      .catch((error) => {
        console.error('Error adding attendee:', error);
      });

    return register;
  };
  return (
    <>
      <Grid
        style={{
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography>Add Attendee:</Typography>
        <FormControl sx={{ width: 280 }} variant="standard">
          <UserSearch
            setUser={(user) => {
              setUser(user);
            }}
            users={filterUsers(users)}
          />
        </FormControl>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenConfirmationBox(true)}
            disabled={isLoadingJoin}
            disableElevation
            sx={{ height: '3rem', marginTop: '0.5rem' }}
          >
            Add Attendee <PersonAdd sx={{ marginLeft: '0.5rem' }} />
          </Button>
        </Grid>
      </Grid>
      <ConfirmationBox
        open={openConfirmationBox}
        title={`Add Selected User?`}
        content={''}
        handleClose={() => {
          setOpenConfirmationBox(false);
        }}
        handleResponse={(response) => {
          if (response) handleAddAttendees();
        }}
      />
    </>
  );
};
export default AddAttendeesDropdown;
